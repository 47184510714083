<template>
	<div
		class="sm-cc relative"
		:class="['sm-cc--' + computedColor, {['sm-cc--' + concentricDesign[0]]: concentricDesign[0] }]"
	>
		<img :src="image" :alt="altText" class="block center w-33 relative" />
		<c-concentric v-if="concentricDesign[1]" :is-big="false" :design="concentricDesign[1]" class="sm-cc__unmasked" />
		<div class="sm-cc__mask" :class="'bg-' + computedColor">
			<c-concentric :is-big="false" :design="concentricDesign[0] ? concentricDesign[0] : 'gear'" />
		</div>
	</div>
</template>

<script>
	import CConcentric from 'src/components/content/Concentric.vue';
	import designColor from 'src/mixins/design-color';

	export default {
		name: 'CConcentricImage',
		components: { CConcentric },
		mixins: [ designColor ],
		props: {
			image: {
				type: String,
				default: null
			},
			altText: {
				type: String,
				default: null
			},
			color: {
				type: String,
				default: null,
				validator: function (value) {
					return ['powder', 'mapplethorpe', 'harriet', 'peach'].indexOf(value) !== -1;
				}
			},
			imageType: {
				type: String,
				default: 'illustration',
				validator: function (value) {
					return ['illustration', 'photo', 'logo'].indexOf(value) !== -1;
				}
			},
			design: {
				type: Array,
				default: null
			},
			cfid: {
				type: String,
				default: '1'
			}
		},
		computed: {
			concentricDesign() {
				switch (this.imageType) {
				case 'illustration':
					return ['vinyl', 'logo-top'];
				case 'photo':
					return ['gear', 'gear-top'];
				case 'logo':
					return ['logo', 'logo-top'];
				default:
					return this.design;
				}
			},
			computedColor() {
				return this.color ? this.color : this.designColor(this.cfid);
			}
		}
	};
</script>

<style lang="scss">
.sm-cc {

	&__mask {
		position: absolute;
		bottom: 15%;
		top: 15%;
		z-index: 0;
		width: 100%;
		overflow: hidden;

		svg {
			min-width: 504px;
		}
	}

	// --tal is a variant which does not have the square image extend past background rectangle
	// but which has a taller bg to contain the square image. Cf. Bottom of Resources page.
	&--tall .sm-cc__mask {
		bottom: 0;
		top: 0;
	}

	&__unmasked {
		min-width: 270px;

		.blog-list & {

			.concentric {
				width: 48.625%;

				.venus {
					stroke: $powder;
				}
			}
		}
	}

	.concentric {
		width: 75%;
	}

	:root &__unmasked {
		z-index: $z1;
		width: 40.5%;
	}

	img {
		/* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
		z-index: $z2;
		min-width: 250px;
	}

	// Color Variations
	&--vinyl {

		&.sm-cc--powder {

			.sm-cc__unmasked {

				.venus {
					fill: $puff;
				}
			}
		}

		&.sm-cc--harriet {

			.sm-cc__mask {

				.mercury {
					stroke: white;
				}

				.venus {
					fill: $alexander;
				}

				.comet {
					stroke: $benjamin;
				}
			}

			.sm-cc__unmasked {

				.venus {
					fill: $abraham;
				}

				.mercury {
					stroke: $harriet;
					stroke-width: 7;
					stroke-dasharray: 10, 30;
				}
			}
		}

		&.sm-cc--peach {

			.sm-cc__mask {

				.venus {
					fill: $summer;
				}

				.comet {
					stroke: $blossom;
				}
			}

			.sm-cc__unmasked {

				.mercury {
					stroke: $jaywalk;
					stroke-dasharray: 2, 10;
				}

				.venus {
					fill: $blossom;
				}
			}
		}

		&.sm-cc--mapplethorpe {

			.sm-cc__mask {

				.venus {
					fill: $brady;
				}
			}

			.sm-cc__unmasked {

				.mercury {
					stroke: $casablanca;
					stroke-width: 15;
					stroke-dasharray: 1, 10;
				}

				.venus {
					fill: $lange;
				}
			}
		}
	}

	&--logo {

		&.sm-cc--harriet {

			.sm-cc__mask {

				.mercury {
					fill: $thetis;
				}

				.venus {
					fill: $abraham;
				}
			}

			.sm-cc__unmasked {

				.mercury {
					stroke: $benjamin;
				}

				.venus {
					stroke: $alexander;
				}
			}
		}

		&.sm-cc--peach {

			.sm-cc__mask {

				.mercury {
					fill: $blossom;
				}

				.venus {
					fill: $summer;
				}
			}

			.sm-cc__unmasked {

				.mercury {
					stroke: $blossom;
					stroke-dasharray: 4, 15;
				}

				.venus {
					stroke: $summer;
				}
			}
		}

		&.sm-cc--mapplethorpe {

			.sm-cc__mask {

				.venus {
					fill: $brady;
				}
			}

			.sm-cc__unmasked {

				.mercury {
					stroke: $casablanca;
					stroke-width: 1;
					stroke-dasharray: 10, 5;
				}
			}
		}
	}

	&--gear {

		&.sm-cc--harriet {

			.sm-cc__mask {

				.sun {
					fill: $abraham;
				}

				.mercury {
					stroke: $jaywalk;
				}

				.venus {
					fill: $blossom;
				}

				.cog {
					stroke: $abraham;
					stroke-width: 4;
				}
			}

			.sm-cc__unmasked {

				.mercury {
					fill: $alexander;
				}
			}
		}

		&.sm-cc--peach {

			.cog {
				stroke: $summer;
				stroke-width: 4;
			}

			.sm-cc__unmasked {

				.mercury {
					fill: $cider;
				}
			}
		}

		&.sm-cc--mapplethorpe {

			.sm-cc__mask {

				.sun {
					fill: $casablanca;
				}

				.cog {
					stroke: $thetis;
					stroke-width: 1;
				}

				.mercury {
					stroke: $casablanca;
				}

				.venus {
					fill: $thetis;
				}

				.earth {
					fill: white;
				}
			}

			.sm-cc__unmasked {

				.mercury {
					fill: $brady;
				}
			}
		}
	}
}

</style>

const BG_COLORS = ['powder', 'mapplethorpe', 'harriet', 'peach'];

export default {
	methods: {
		designColor(cfid) {
			const colors = BG_COLORS.concat(BG_COLORS).concat(BG_COLORS);
			const colorIndex = cfid ? +cfid.substr(-1) : Math.floor(Math.random() * 4);
			return colors[colorIndex];
		}
	}
};
<template>
	<component :is="!!to ? 'router-link' : 'div'" :to="to" class="arrow-link">
		<span :class="{ 'underline' : linkUnderline }">
			<slot />
		</span>
		<svg v-if="!$amp" class="arrow-link__arrow" focusable="false">
			<defs>
				<marker :id="id" markerWidth="10" markerHeight="10" refX="50" refY="50" viewBox="0 0 55 100">
					<polyline points="10,90 50,50 10,10" fill="none" stroke="currentColor" stroke-width="10" />
				</marker>
			</defs>
			<line x1="0" y1="50%" x2="95%" y2="50%" stroke-width=".08em" :marker-end="`url(#${id})`" stroke="currentColor" />
		</svg>
	</component>
</template>

<script>
	import { v4 as uuidv4 } from 'uuid';

	export default {
		name: 'CArrowLink',
		props: {
			to: {
				default: null,
				type: String
			},
			linkUnderline: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				id: uuidv4()
			};
		}
	};
</script>

<style lang="scss">
	.arrow-link {
		display: inline-block;
		vertical-align: middle;
		color: $deep;
		font-family: $header;
		font-weight: 900;
		letter-spacing: .05em;
		text-decoration: none;
		text-transform: uppercase;

		&__arrow {
			width: .9em;
			height: .8em;
			transition: width .2s ease;
		}

		&:hover {
			color: $matins;

			.arrow-link__arrow {
				width: 1.25em;
			}
		}
	}

</style>